<template>
  <div ref="pdfPreview" class="pdf-preview">
    <div class="loading-mask" style="z-index: 8" v-if="useLoading && loading" v-loading="loading" element-loading-text="文档正在加载中"  element-loading-spinner="el-icon-loading"></div>
  <div    :id="containerOutside?'':pdfContainerId" class="pdf-container" >
    <div  :id="pdfViewerId" class="pdfViewer" @mouseup="viewerMouseUp" @mousedown="viewerMousedown">
    </div>

  </div>
  <div v-show="pdfInlineToolVisible" ref="inlineToolRef" style="position: fixed;z-index: 99;" :style="{top:pdfInlineToolTop,left:pdfInlineToolLeft}">
    <slot name="pdfInlineTool" >
    </slot>
  </div>
  </div>

</template>
<script>
import config from "../config";
import {
  getContractPreviewStatus,
  getContractVersionPreviewStatus,
  getPreviewFileVersionNum
} from "../api/api";
import {ElMessage} from "element-plus";
import { getContractPdfUrl, getContractVersionPdfUrl, initPdfView} from "./common/pdfView";
import * as $ from 'jquery';
import {FileTypeName} from "../constant/contract";
import {colorLog, debounce, DomEventListenerManager, MetaLogger, singleListenerManager} from "../util";
import {single} from "./common/question";
const PDF_PREVIEW_EVENTS = {
  SCROLL_STOPPED:0,
}
export default {
  name: "PdfPreview",
  props: {
    "contractId":{
      require:false
    },
    "versionId":{
      require:false
    }
    ,
    "previewSuccess":{
      require:false,
    }
    ,
    "default_scale":{
      require:false,
    }
    ,
    "containerOutside":{
      require:false,
    },
    useLoading:{
      require:false,
      default:true
    },
    viewOcrMode:{
      required:false,
      default:false
    },
    pollUntilEditable:{
      required:false,
      default:false
    },
    originVersionId:{
      required:false,
      default:null
    },
    //调整scale,让页宽和pdf-viewer的宽度相等
    scaleContainViewer:{
      required:false,
      default:false
    }
  },
  data() {
    return {
      fileUrl: '',
      loading: true,
      pdfContainerId:this.useExternalPdfContainer ? this.externalPdfContainerId : 'pdf-container',
      pdfViewerId: 'pdf-viewer',
      pdfInfo: null,
      pdfContent: '',
      pdfInlineToolVisible:false,
      pdfInlineToolTop:0,
      pdfInlineToolLeft:0,
      previewFileVersionNum:null,
      timeout:null,
      // todo 现在默认是合同文件，未来可能变成其它文件
      fileTypeName:FileTypeName.ContractFile,
      queryFinished:true,
      domEventListenerManager:new DomEventListenerManager(),
      currentSelectionRange:null
    };
  },
  created() {
    this.domEventListenerManager.registerListener(window,'click',(e)=>{
      if(!this.$refs.pdfPreview) return;
      if(e.target.closest('.pdf-preview') === this.$refs.pdfPreview){
        return;
      }else{
        this.hidePdfInlineTool();
      }
    })
  },
  watch: {
    'versionId': function (newVal,oldVal) {
      colorLog.red("versionId Changed:old->"+oldVal+" new->"+newVal)
      this.checkStatus(newVal);
    },
    'viewOcrMode': function (newVal,oldVal) {
      colorLog.red("versionMode Changed")
      this.checkStatus(this.versionId);
    }

  },
  mounted() {
    if(this.contractId!= null){
      this.checkStatus(this.versionId);
    }
    console.log(this.$slots);


  },
  unmounted() {
    clearTimeout(this.timeout);
    this.domEventListenerManager.removeListener();
  },
  methods: {
    getPdfInfo(){
      return this.pdfInfo
    },
    getPreviewFileVersionNum(){
      return this.previewFileVersionNum;
    },
    viewerMouseUp:function(e){
      if (e.button !== 0) {
        return
      }
      this.showPdfInlineToolWhenSelectText(e);
    },
    viewerMousedown(event){
      if(event.button === 2) return;
      window.getSelection().removeAllRanges();
    },
    showPdfInlineToolWhenSelectText:function(e){
      if (!this.$slots.pdfInlineTool || this.$refs.inlineToolRef.contains(e.target)) {
        return
      }
      let selection = window.getSelection();
      let sText = selection.toString().trim().replace(/\n/g, "");
      if(sText.length>0) {
        this.currentSelectionRange = selection.getRangeAt(0);
        this.showPdfInlineTool(e.pageX, e.pageY);
      }else{
        this.hidePdfInlineTool()
      }
    },
    hidePdfInlineTool(){
      if (!this.$slots.pdfInlineTool) {
        return
      }
      this.pdfInlineToolVisible = false;
    },
    showPdfInlineTool(x, y) {
      if (!this.$slots.pdfInlineTool) {
        return
      }
      this.pdfInlineToolTop = y + 5 + 'px';
      this.pdfInlineToolLeft= x + 'px';
      this.pdfInlineToolVisible = true;
    },
    checkPreviewStatusPromise(){
      if(this.contractId){
        // let versionId;
        // if(this.viewOcrMode){
        //   versionId = this.originVersionId
        // }else{
        //   versionId = this.versionId;
        // }
        // 因为扫描版显示了文字版的评论，所以，扫描版查询状态时需要查询文字版的可预览状态。就不用再额外处理originVersionId的情况。
        let versionId = this.versionId

        if(this.versionId || this.viewOcrMode){
          return getContractVersionPreviewStatus(versionId);
        }else{
          return getContractPreviewStatus(this.contractId)
        }
      }else{
        throw new Error("contract不能为空");
      }
    },
    async checkStatus(versionId) {

      if(!this.queryFinished)return;
      this.queryFinished=false;
      this.checkPreviewStatusPromise().then(res => {
        MetaLogger.warning("checkStatus:versionId->"+versionId+" success->"+res.data.data);
        this.queryFinished=true;
        if(res.data.code===0) {
          let result = res.data.data;
          let ratio = res.data.ratio;
          this.$emit('preview-progress-ratio-updated',ratio)
          this.showMessageByCode(result);
          if (result && (!this.pollUntilEditable || ratio === 100)) {
            // 转换成功
            if (this.viewOcrMode) {
              // 扫描版下载文件时需要下载扫描版的pdf文件。
              versionId = this.originVersionId
            }

            if (this.versionId || this.viewOcrMode) {
              this.fileUrl = getContractVersionPdfUrl(versionId);
            } else {
              this.fileUrl = getContractPdfUrl(this.contractId);
            }

            this.previewReady();

          } else {
            // 仍在转换中
            this.loading = true;
            this.timeout = setTimeout(() => this.checkStatus(versionId), 1000);
          }
        }else{
          this.$message.error('文档处理失败')
          this.loading=false;
          this.$emit("loadPdfFailed")
        }
      }).catch(error => {
        this.queryFinished=true;
        console.log(error)
        this.$message.error('文档预览失败')
      })
    },
    previewReady:function(){
        getPreviewFileVersionNum(this.versionId).then(res=>{
            if (res.data.code === 0) {
                this.previewFileVersionNum = res.data.data
            }
        }).finally(()=>{
            this.initPdfView(this.fileUrl);
        })
    },
    getCookie: function (cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      console.log("获取cookie,现在循环")
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        console.log(c)
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) != -1){
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    initPdfView: function (fileUrl) {
      initPdfView({
        fileUrl: fileUrl, pdfContainerId: this.pdfContainerId, pdfViewerId: this.pdfViewerId,
        addPdfEventListener: this.addPdfEventListener,defaultScale:this.default_scale || null,
      }).then(pdfInfo => {
        this.pdfInfo = pdfInfo;
        if(this.$props.scaleContainViewer){
          const pdfViewerWidth = document.getElementById(pdfInfo.pdfViewId).clientWidth;
          const pageWidth = pdfInfo.getPeerPageRect().width;
          pdfInfo.setScale(pdfViewerWidth / pageWidth);
        }

        this.pdfContent = this.pdfInfo.replaceAllSpace(pdfInfo.pagesContent.join(""))
        this.previewSuccess(this.pdfContainerId,pdfInfo);
        this.initCustomEventListener();

        let stopFn = debounce(()=>{
          singleListenerManager.emit(PDF_PREVIEW_EVENTS.SCROLL_STOPPED);
        },200)
        let pdfContainer = document.getElementById(this.pdfContainerId);
        pdfContainer.addEventListener('scroll',stopFn);
        return pdfInfo
      })
    },
    // 监听pdf js 抛出来的事件
    addPdfEventListener: function (eventBus) {
      // eventBus.on('pdfPageContentInitialized',(pagesContent)=>{
      //     console.log('***********pdfPageContentInitialized')
      //     console.log(pagesContent)
      //     console.log('***********pdfPageContentInitialized')
      // })
      eventBus.on('textlayerrendered',(param) => {
        // console.log('textlayerrendered*********')
        // console.log(param);
        this.loading = false;
        singleListenerManager.emit(`page${param.pageNumber}Rendered`,param)
        this.$emit("pdfPageRendered",param)
        // console.log('textlayerrendered*********')
      })
    },
    // 监听自定义的事件，不同于addPdfEventListener
    initCustomEventListener:function(){
      document.getElementById(this.pdfViewerId).addEventListener('click',  (e) =>{
        this.$emit('pdfClick',e)
      });
    },
    showAlertLayer:function(){

    },
    showMessageByCode: function (code) {
      switch (code) {
        case 0:
          //ElMessage.info('文件正在转换中，请稍后重试');
          return;
        case 2:
          this.$message.error('不支持的类型！');
          return;
        case 3:
          ElMessage.info('文件预览出错！');
          return;
        case 4:
          //ElMessage.info('文件转换超时，请稍后重试');
          return;
        case -1:
          return;
        case -2:
          ElMessage.info('你没有此操作的权限！');
          return;
      }
      // code小于0，其实也相当于<-2 ,因为上面已经对-1，-2做了区分
      if (code < 0) {
        ElMessage.info('文件不存在！');
        return;
      }
    },
    showRelationInPdf: function (start, end) {
      if (start < 0 || end <= 0) {
        return;
      }
      if(this.pdfInfo.pagesContent.length === this.pdfInfo.pdfDoc._pdfInfo.numPages) {
        if(this.pdfInfo.highlightTimeId!=null){
          clearTimeout(this.pdfInfo.highlightTimeId)
          this.pdfInfo.highlightTimeId = null
        }
        let lastEle = $(".promptHighlight")
        if(lastEle!=null){
          lastEle.remove();
        }
        let pageContentCount = 0;
        let pageIndex = 1;
        let relationInfo = {
          start:start,
          end:end,
          startPage:-1,
          endPage:-1,
        };
        for(; pageIndex<=this.pdfInfo.pdfDoc._pdfInfo.numPages; pageIndex++){
          let currentPdfPageContentLength = this.pdfInfo.replaceAllSpace(this.pdfInfo.pagesContent[pageIndex-1]).length;
          pageContentCount += currentPdfPageContentLength;
          if(pageContentCount>start && relationInfo.startPage===-1){
            relationInfo.startPage = pageIndex;
            relationInfo.start = start - (pageContentCount-currentPdfPageContentLength);
          }
          if(pageContentCount>=end){
            relationInfo.endPage = pageIndex;
            relationInfo.end = end - (pageContentCount-currentPdfPageContentLength);
            if(this.pdfInfo.pdfViewer._currentPageNumber !== relationInfo.startPage){
              this.scrollByPage(relationInfo.startPage)
            }
            break
          }
        }
        this.highlightRelation(relationInfo)
      }else{
        ElMessage.info('正在索引中，请稍后重试');
      }
    },
    highlightRelation: function (relationInfo) {
      let pageRanges = this.getFixedPageRanges(relationInfo)
      if (pageRanges == null) {
        singleListenerManager.on(`page${relationInfo.startPage}Rendered`,(param)=>{
          this.highlightRelation(relationInfo);
        },true);
        // setTimeout( () => {
        //   this.highlightRelation(relationInfo)
        // },500)
        return
      }
      let waitScroll = true;
      for(let pageRange of pageRanges){
        const page = $("div[data-page-number='"+pageRange.pageNum+"']")
        let textLayer = page.children(".textLayer");
        if(textLayer.children(".endOfContent").length ===0){
          continue
        }
        let allSpan = $(textLayer).children("span");
        let exactBox = this.getExactBoxInPageByPosition(pageRange, allSpan);
        let minLeft=9999999,maxRight=-1;
        for(let index=0;index<allSpan.length;index++){
          let span = allSpan[index]
          minLeft = Math.min(span.offsetLeft,minLeft)
          maxRight = Math.max(span.offsetLeft+span.offsetWidth,maxRight)
        }
        let leftOffset = $(textLayer).offset().left;
        let topOffset = $(textLayer).offset().top;
        for(let box in exactBox){
          let promptHighlightDiv = this.createPromptHighlightDiv(exactBox[box],leftOffset,topOffset)
          textLayer.prepend(promptHighlightDiv)
          if(waitScroll){
            waitScroll = false
            // let matchTop = promptHighlightDiv.offset().top;
            // let currentView = $("#" + this.pdfViewerId);
            // const currentViewScrollTop = currentView.scrollTop();
            // const windowHeight = $(window).height();
            // const _scrollTop = currentViewScrollTop + matchTop - (windowHeight - 150) / 2;
            // currentView.animate({scrollTop: _scrollTop}, 300);
            // document.querySelector(".promptHighlight").scrollIntoView({block:'nearest', inline:'nearest'});
            document.querySelector(".promptHighlight").scrollIntoView({block:'center', inline:'center',behavior:"smooth"});
            singleListenerManager.on(PDF_PREVIEW_EVENTS.SCROLL_STOPPED,()=>{
              this.pdfInfo.highlightTimeId = setTimeout(() => {
                this.pdfInfo.highlightTimeId = null;
                let lastEle = $(".promptHighlight");
                if(lastEle!=null){
                  lastEle.remove()
                }
              },2000)
            },true);
          }
        }
      }
    },
    getExactBoxInPageByPosition: function (textMeta,allSpan) {
      let exactBox = {}
      let elementRange = this.getElementRangeByIndexRange(allSpan,textMeta.eleStart,textMeta.eleEnd)
      let startInfo = elementRange[0]
      let endInfo = elementRange[1]
      // let startInfo = getElementByIndex(allSpan,textMeta.eleStart,false);
      let isPageFirstSpan = startInfo.index===0 && startInfo.offset === 0;
      // let endInfo= getElementByIndex(allSpan,textMeta.eleEnd,true);
      let isPageLastSpan = endInfo.index===allSpan.length-1 && endInfo.offset === endInfo.ele.textContent.length;
      let textRange = document.createRange();

      textRange.setStart(startInfo.ele.childNodes[0],startInfo.offset);
      textRange.setEnd(endInfo.ele.childNodes[0],endInfo.offset);
      let textRangeRects = textRange.getClientRects();
      let textRangeRect = textRange.getBoundingClientRect();
      let bodyBox = {
        top:textRangeRect.top,
        bottom:textRangeRect.bottom,
        left:textRangeRect.left,
        right:textRangeRect.right
      }
      let headRect = textRangeRects[0]
      let footRect = textRangeRects[textRangeRects.length-1]
      if(headRect.left!==textRangeRect.left && !isPageFirstSpan){//整体左坐标不同代表起始不在一行的最左端 需要降低body top一行的高度并且产生head矩阵
        bodyBox.top = headRect.bottom
        exactBox["head"] = {
          top:headRect.top,
          bottom:headRect.bottom,
          left:headRect.left,
          right:textRangeRect.right
        }
      }
      exactBox["body"] = bodyBox
      if(footRect.right!==textRangeRect.right && !isPageLastSpan){//整体右坐标不同代表结尾不在一行的最右端 需要降低body bottom一行的高度并且产生foot矩阵
        bodyBox.bottom = footRect.top
        exactBox["foot"] = {
          top:footRect.top,
          bottom:footRect.bottom,
          left:textRangeRect.left,
          right:footRect.right
        }
      }
      return exactBox
    },
    getElementRangeByIndexRange: function (allSpan,start,end) {
      let offset = 0;
      let startInfo = null,endInfo = null;
      for(let i=0;i<allSpan.length;i++){
        let span = allSpan[i];
        if (span.className === "markedContent") {
          continue;
        }
        let spanTextLength = span.textContent.length;
        offset+=spanTextLength;
        if(offset>start){
          if(!startInfo || startInfo.ele.offsetTop - span.offsetTop > 100){
            startInfo = {
              "ele":span,
              "offset":Math.max(start + spanTextLength - offset,0),
              "index":i
            }
          }
        }
        if(startInfo!=null && !end){
          endInfo = {
            "ele": allSpan[allSpan.length - 1],
            "offset": allSpan[allSpan.length - 1].textContent.length,
            "index":allSpan.length - 1
          }
        }else if(offset>=end){
          endInfo = {
            "ele":span,
            "offset":end + spanTextLength - offset,
            "index":i
          }
        }
        if(startInfo && endInfo){
          return [startInfo,endInfo]
        }
      }
    },
    createPromptHighlightDiv: function (box,leftOffset,topOffset) {
      let promptHighlightTemplate = "<div class='promptHighlight' style='position:absolute;'></div>"
      let promptHighlightDiv = $(promptHighlightTemplate)
      promptHighlightDiv.css("left",`${box.left - leftOffset}px`)
      promptHighlightDiv.css("width",`${box.right - box.left}px`)
      promptHighlightDiv.css("top",`${box.top - topOffset}px`)
      promptHighlightDiv.css("height",`${box.bottom-box.top}px`)
      return promptHighlightDiv
    },
    getFixedPageRanges: function (rangeInfoInPage) {
      let pageElement = $("div[data-page-number='"+rangeInfoInPage.startPage+"']")
      let textElement = $(pageElement).children(".textLayer")
      if(textElement.children(".endOfContent").length ===0){
        return null;
      }
      let result = []
      for(let pageIndex = rangeInfoInPage.startPage;pageIndex<=rangeInfoInPage.endPage;pageIndex++){
        let keywordRange;
        if(pageIndex === rangeInfoInPage.startPage){
          keywordRange = {
            start:rangeInfoInPage.start,
            end:rangeInfoInPage.startPage===rangeInfoInPage.endPage?rangeInfoInPage.end:null
          };
        }else{
          pageElement = $("div[data-page-number='"+pageIndex+"']")
          textElement = $(pageElement).children(".textLayer")
          if(textElement.children(".endOfContent").length ===0){
            continue
          }
          if(pageIndex === rangeInfoInPage.endPage){
            keywordRange = {
              start:0,
              end:rangeInfoInPage.end
            };
          }else{
            keywordRange = {
              start:0,
              end:null
            };
          }
        }

        this.fixOffsetFromContentToEleText(textElement.text(),this.pdfInfo.replaceAllSpace(this.pdfInfo.pagesContent[pageIndex-1]),keywordRange);
        result.push({
          pageNum: pageIndex,
          eleStart: keywordRange.start,
          eleEnd: keywordRange.end
        });
      }
      return result
    },
    scrollByPage: function (targetPageNum) {
      var targetPage = document.querySelector('[data-page-number="'+targetPageNum+'"');
      targetPage.scrollIntoView({behavior:'smooth',block:'center',inline:'center'});
    },
    fixOffsetFromContentToEleText: function (eleText, content, keywordRange) {
      let i=0,j=0;
      let start=null,end=null;
      for(;i<eleText.length&&j<content.length;i++,j++){
        let count=0;
        while (eleText[i+count]!==content[j]){
          count++;
          if(count===1000){
            // TODO: 如果有超过一千个空格，还是会匹配失败
            ElMessage.info('匹配失败');
            return
          }
        }
        i+=count;
        if(!start && keywordRange.start===j){
          start = i;
          if(!keywordRange.end){
            break
          }
        }
        if(keywordRange.end-1 === j){
          end=i+1;
          break
        }
      }
      keywordRange.start = start;
      keywordRange.end = end;
    }
  }
}
</script>

<style>
.pdfViewer{
  box-sizing: content-box;
}
.pdfViewer *{
  box-sizing: content-box;
}
</style>
<style scoped>
#pdf-container {
  /*height: 100%;*/
  width: 100%;
  position: absolute;
  /*overflow: auto;*/
}
#pdf-viewer{
  /*width: 100%;*/
}
#pdf-viewer >>> .page{
  box-sizing: content-box !important;
}
.loading-mask{
  position: fixed;
  height: calc(100% - 200px);
  width: 816px;

  z-index: 999;
}
</style>
<style>

.promptHighlight{
  /*background-color: #E6A23C;*/
  animation: promptHighlightBackground 2s;
  -moz-animation: promptHighlightBackground 2s;
  -webkit-animation: promptHighlightBackground 2s;
  -o-animation: promptHighlightBackground 2s;
}
@keyframes promptHighlightBackground {
  0% {
    background: transparent;
  }
  30% {
    background: orange;
  }
  100% {
    background: transparent;
  }
}
@media screen  and (max-width: 800px){
  .loading-mask{
    width: 100% !important;
    height: 100vh !important;
  }
  /*覆盖pdf.js的样式*/
  #pdf-container[id]{
    width: auto;
    left:0;
    right:0;
    margin:0 -4%;
  }

  #pdf-viewer[id]{
    overflow-x: auto;
    overflow-y: hidden;
  }
  #pdf-viewer::after{
    content:'';
    height: 10px;
    display: block;
  }
  .pdfViewer .page[class]{
    margin: 1px auto -16px;
  }
}

/*#pdf-viewer {*/
/*  position: absolute;*/
/*  !*height: calc(100% - 150px);*!*/
/*  width: calc(100% - 360px);*/
/*  padding: 0 10px;*/
/*  overflow-y: scroll;*/
/*  overflow-x: hidden;*/
/*}*/
</style>
